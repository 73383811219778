import { AxiosResponse } from 'axios'
import { CloudfrontEntry } from '../types'
import { showroomConfig } from '../config'
import { HttpService } from './http'

export interface ShowroomData {
  experts: ShowroomItem[]
  guidedTours: ShowroomItem[]
}

export interface ShowroomDataConfig {
  language: string
}

export interface ShowroomItem {
  slug: string
  label: string
  video: string
  hotspot?: {
    src: string
    map: string
  }
}

export class ShowroomService {
  public static async getData(
    config: ShowroomDataConfig
  ): Promise<ShowroomData | undefined> {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${showroomConfig.url}`,
    })
    if (!result) {
      return
    }
    const transformer = new ShowroomTransformer(result.data, config)
    return transformer.parseData()
  }

  public static async getStaticData(
    config: ShowroomDataConfig
  ): Promise<ShowroomData | undefined> {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${showroomConfig.staticUrl}`,
    })
    if (!result) {
      return
    }
    return result.data
  }
}

export class ShowroomTransformer {
  private config: ShowroomDataConfig
  private data: CloudfrontEntry[]
  private baseTree: CloudfrontEntry | undefined
  private language: string
  public constructor(data: CloudfrontEntry[], config: ShowroomDataConfig) {
    this.data = data
    this.config = config
    this.baseTree = this.data.find(
      ({ ContentType }) => ContentType === 'SI-VideoMicrosite'
    )

    this.language = this.config.language === 'de' ? 'de-DE' : 'en-US'
  }

  public parseData() {
    return {
      experts: this.getExpertVideos(),
      guidedTours: this.getGuidedToursVideos(),
    }
  }

  public getExpertVideos() {
    const node = this.baseTree?.ContentFieldValues.expertVideos[this.language]
    const ret: ShowroomItem[] = []
    node.map((uid: string) => {
      const add = this.parseExpertNode(uid)
      if (add) {
        ret.push(add)
      }
    })

    return ret
  }

  public getGuidedToursVideos() {
    return guidedTourVideos

    const node = this.baseTree.ContentFieldValues.recordedGuidedTours[
      this.language
    ]
    const ret: ShowroomItem[] = []
    node.map(uid => {
      const add = this.parseGuidedNode(uid)
      if (add) {
        ret.push(add)
      }
    })

    return ret
  }

  public parseExpertNode(id: string): ShowroomItem | undefined {
    const d = this.data.find(({ Uid }) => Uid === id)

    if (!d) {
      return
    }

    const mappedData = mapData.find(
      ({ uid }) => uid[this.config.language] === id
    )

    if (!mappedData) {
      return
    }

    return {
      slug: d.Uid,
      label: d.ContentFieldValues.name[this.language],
      video:
        'https://sbc21.cdn.cuenect.online/videos/transcoded/Siemens-Teaser_Smart-Building-Connect_2021_HD1080_25_3mBits_-18LUFS_12052021a/hls.m3u8',

      /*       video: `${showroomConfig.assets}${d.AssetDataSet.AssetFileProperties.FileHash}`,
       */ hotspot: mappedData.hotspot,
    }
  }

  public parseGuidedNode(id: string): ShowroomItem | undefined {
    const d = this.data.find(({ Uid }) => Uid === id)

    return {
      slug: d.Uid,
      label: d.ContentFieldValues.name[this.language],
      video: `${showroomConfig.assets}${d.AssetDataSet.AssetFileProperties.FileHash}`,
    }
  }
}

const guidedTourVideos = [
  {
    slug: 'ejnpmqh7x6ib7hmqiakjbiijsbqndu6wjipki',
    label: 'English',
    video:
      'https://sbc21.cdn.cuenect.online/videos/transcoded/Siemens-Teaser_Smart-Building-Connect_2021_HD1080_25_3mBits_-18LUFS_12052021a/hls.m3u8',
  },
]

/* const mapData = [
  {
    slug: 'highlight',
    uid: {
      en: 'd3mgpo3eqcw72wwlsnzori4rfd5odaxrtta6g',
      de: '3vfcj3zkssf3cjxitlwlbigeub5i6enyrd222',
    },
    hotspot: {
      src: '/assets/showroom/highlight.png',
      map: 'M742,455.1l195,125l233-82l7-183l-185-95l-203,60L742,455.1z',
    },
  },
  {
    slug: 'expert_01',
    uid: {
      en: 'p3zvqogizv2jlamj4auoapk3ddfsvff4waiiy',
      de: 'eialv6nozkwyyy6xy4o6yvwmfcir4ieuvqnzu',
    },
    hotspot: {
      src: '/assets/showroom/expert_01.png',
      map: 'M959,160.1l92,9l53-49l-39-54l-87-7l-54,45L959,160.1z',
    },
  },
  {
    slug: 'expert_02',
    uid: {
      en: 'o25ot4lcoldbsoy6r7patpozkmkd6b2be2sok',
      de: 'jkwar7xlaferfvwtiwgp7w7h4y7if2m4zyvu2',
    },
    hotspot: {
      src: '/assets/showroom/expert_02.png',
      map: 'M1064,174.1l41,62l99,9l50-54l-46-58l-91-8L1064,174.1z',
    },
  },
  {
    slug: 'expert_03',
    uid: {
      en: 'rfz4oh7ov5efnfwysg64kmuzdpi2356oa6kxg',
      de: '73ilijyoytkeq45xemmoap6zywchklbvmbunw',
    },
    hotspot: {
      src: '/assets/showroom/expert_03.png',
      map: 'M1218,253.1l50-54l97,9l54,64l-47,59l-104-10L1218,253.1z',
    },
  },
  {
    slug: 'expert_04',
    uid: {
      en: 'k3doqktladi4w7fconvwdwe5o2cezdndq6c6o',
      de: 'bax56onmdzu362cofgtvw4tbqfcqnijsscxfy',
    },
    hotspot: {
      src: '/assets/showroom/expert_04.png',
      map: 'M467,483.1l23,88l113,13l82-76l-30-82l-105-12L467,483.1z',
    },
  },
  {
    slug: 'expert_05',
    uid: {
      en: 'rtppgidujljwpdv26htymy5mckkwe6qekaof6',
      de: 'ebsn3q5jybs63ao2ida2qyh4u2wjawdjygq4s',
    },
    hotspot: {
      src: '/assets/showroom/expert_05.png',
      map: 'M532,326.1l23,74l104,11l74-64l-29-69l-98-10L532,326.1z',
    },
  },
  {
    slug: 'expert_06',
    uid: {
      en: 'xn27xcrwxpxrylzdjj73k7lmqskwpvdsw7xke',
      de: 'xo6lwfqwp6lhx5s5ujyik3tdt732cbmot6x4i',
    },
    hotspot: {
      src: '/assets/showroom/expert_06.png',
      map: 'M587,194.1l25,64l96,9l67-55l-30-60l-90-8L587,194.1z',
    },
  },
]
 */

const mapData = [
  {
    slug: 'highlight',
    uid: {
      en: 'd3mgpo3eqcw72wwlsnzori4rfd5odaxrtta6g',
      de: '3vfcj3zkssf3cjxitlwlbigeub5i6enyrd222',
    },
    hotspot: {
      src: '/assets/showroom/highlight.png',
      map: 'M742,455.1l195,125l233-82l7-183l-185-95l-203,60L742,455.1z',
    },
  },
  {
    slug: 'expert_01',
    uid: {
      en: 'k3doqktladi4w7fconvwdwe5o2cezdndq6c6o',
      de: 'eialv6nozkwyyy6xy4o6yvwmfcir4ieuvqnzu',
    },
    hotspot: {
      src: '/assets/showroom/expert_01.png',
      map: 'M959,160.1l92,9l53-49l-39-54l-87-7l-54,45L959,160.1z',
    },
  },
  {
    slug: 'expert_02',
    uid: {
      en: 'rtppgidujljwpdv26htymy5mckkwe6qekaof6',
      de: 'jkwar7xlaferfvwtiwgp7w7h4y7if2m4zyvu2',
    },
    hotspot: {
      src: '/assets/showroom/expert_02.png',
      map: 'M1064,174.1l41,62l99,9l50-54l-46-58l-91-8L1064,174.1z',
    },
  },
  {
    slug: 'expert_03',
    uid: {
      en: 'xn27xcrwxpxrylzdjj73k7lmqskwpvdsw7xke',
      de: '73ilijyoytkeq45xemmoap6zywchklbvmbunw',
    },
    hotspot: {
      src: '/assets/showroom/expert_03.png',
      map: 'M1218,253.1l50-54l97,9l54,64l-47,59l-104-10L1218,253.1z',
    },
  },
  {
    slug: 'expert_04',
    uid: {
      en: 'p3zvqogizv2jlamj4auoapk3ddfsvff4waiiy',
      de: 'bax56onmdzu362cofgtvw4tbqfcqnijsscxfy',
    },
    hotspot: {
      src: '/assets/showroom/expert_04.png',
      map: 'M467,483.1l23,88l113,13l82-76l-30-82l-105-12L467,483.1z',
    },
  },
  {
    slug: 'expert_05',
    uid: {
      en: 'o25ot4lcoldbsoy6r7patpozkmkd6b2be2sok',
      de: 'ebsn3q5jybs63ao2ida2qyh4u2wjawdjygq4s',
    },
    hotspot: {
      src: '/assets/showroom/expert_05.png',
      map: 'M532,326.1l23,74l104,11l74-64l-29-69l-98-10L532,326.1z',
    },
  },
  {
    slug: 'expert_06',
    uid: {
      en: 'rfz4oh7ov5efnfwysg64kmuzdpi2356oa6kxg',
      de: 'xo6lwfqwp6lhx5s5ujyik3tdt732cbmot6x4i',
    },
    hotspot: {
      src: '/assets/showroom/expert_06.png',
      map: 'M587,194.1l25,64l96,9l67-55l-30-60l-90-8L587,194.1z',
    },
  },
]
