import { GenIcon, IconBaseProps } from 'react-icons'
export const BueClockClose = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M16.2,18.8c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6C15.7,19,15.9,18.8,16.2,18.8z M7.2,19.4		c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C7.5,18.8,7.2,19.1,7.2,19.4z M4.1,16.3c0,0.3,0.3,0.6,0.6,0.6		c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C4.3,15.7,4.1,15.9,4.1,16.3z M11.4,20.5c0,0.3,0.3,0.6,0.6,0.6		c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C11.6,19.9,11.4,20.2,11.4,20.5z M18.7,7.8c0,0.3,0.3,0.6,0.6,0.6		c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C19,7.2,18.7,7.5,18.7,7.8z M20.9,12.5h0.2c0.1,0,0.2-0.1,0.2-0.2v-0.6		c0-0.1-0.1-0.2-0.2-0.2h-0.2c-2.1,0-4.2,0-6.3,0l-0.9,0.9C16.1,12.5,18.5,12.5,20.9,12.5z M18.8,16.2c0,0.3,0.3,0.6,0.6,0.6		c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C19.1,15.6,18.8,15.9,18.8,16.2z M4.6,7.2C4.3,7.2,4,7.5,4,7.8c0,0.3,0.3,0.6,0.6,0.6		c0.3,0,0.6-0.2,0.6-0.6C5.2,7.5,4.9,7.2,4.6,7.2z M16.8,4.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6		C16.5,5.2,16.8,5,16.8,4.6z M3.5,11.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6S3.8,11.4,3.5,11.4z		 M20.5,5.7c1.3,1.8,2.1,4,2.1,6.4c0,5.8-4.8,10.6-10.6,10.6c-2.4,0-4.5-0.8-6.3-2.1l-1,1c2,1.6,4.6,2.5,7.4,2.5c6.6,0,12-5.4,12-12		c0-2.7-0.9-5.3-2.5-7.3L20.5,5.7z M3.5,18.3c-1.3-1.8-2.1-4-2.1-6.3C1.4,6.2,6.2,1.4,12,1.4c2.3,0,4.5,0.8,6.3,2.1l1-1		C17.3,0.9,14.7,0,12,0C5.4,0,0,5.4,0,12c0,2.8,0.9,5.3,2.5,7.3L3.5,18.3z M8.6,7.9C8.3,7.6,8,7.3,7.7,7C7.6,6.9,7.5,6.9,7.4,7		C7.3,7.1,7.1,7.3,7,7.4C6.9,7.5,6.9,7.6,7,7.6c1.2,1.2,2.4,2.4,3.6,3.6l0.7-0.7C10.4,9.7,9.5,8.8,8.6,7.9z M12.7,3.5		c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6C12.4,4.1,12.7,3.8,12.7,3.5z M8.4,4.7c0-0.3-0.3-0.6-0.6-0.6		c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6C8.1,5.3,8.4,5,8.4,4.7z M24,1l-1-1l-23,23l1,1L24,1',
        },
        child: [],
      },
    ],
  })(props)
