import styled from '@emotion/styled'
import { Video as CoreVideo } from 'cuenect-web-core'
import { HLSUtil, VideoProps, withVideoControls } from 'cuenect-web-core'
import React from 'react'
import { mq } from '../../../utility'
import { AnalyticsTransformer, TrackingData } from '../../../utility'
import cq from '../../../utility/coreQuery'
interface ExtendedVideoProps extends VideoProps {
  track?: string
  onTrackLoaded?(data: TextTrackCueList): void
  onTrackChange?(data: TextTrackCue): void
  playerName?: string
  trackingTitle?: string
}

export const VideoWithControls = React.forwardRef<HTMLVideoElement, VideoProps>(
  (props, ref) => withVideoControls(Video, props, ref)
)
export const Video = React.forwardRef<HTMLVideoElement, ExtendedVideoProps>(
  (
    {
      track,
      onTrackLoaded,
      onTrackChange,
      playerName = 'VoD',
      trackingTitle = '',
      ...props
    },
    ref
  ) => {
    const chapterElem = React.useRef()
    const [
      analytics,
      setAnalytics,
    ] = React.useState<AnalyticsTransformer | null>(null)

    const trackData = (data: TrackingData) => {
      if (analytics) {
        analytics.transformTrackingEvent(data)
      }

      //tracking.transformTrackingEvent(analytics)
    }

    const interalRef = React.useRef<HTMLVideoElement>()
    const refCallback = React.useCallback<(node: HTMLVideoElement) => void>(
      node => {
        interalRef.current = node
        if (!ref) {
          return
        }
        if (typeof ref === 'function') {
          ref(node)

          return
        }
        ref.current = node
      },
      []
    )

    React.useEffect(() => {
      // Init Tracking
      const tracker: AnalyticsTransformer = new AnalyticsTransformer()
      setAnalytics(tracker)
      tracker.setPlayername(playerName)

      if (interalRef.current) {
        if (props.src) {
          tracker.bindMediaTracking(interalRef.current, {
            src: props.src,
            title: trackingTitle,
          })
        }

        if (!props.src && HLSUtil.getUrl()) {
          tracker.bindMediaTracking(interalRef.current, {
            src: HLSUtil.getUrl(),
            title: trackingTitle,
          })
        }

        return () => {
          tracker.unbindMediaTracking(interalRef.current)
        }
      }
    }, [interalRef.current, HLSUtil.getUrl(), trackingTitle])

    const trackLoaded = () =>
      onTrackLoaded && onTrackLoaded(chapterElem.current.track.cues)

    const trackChange = () =>
      onTrackChange && onTrackChange(chapterElem.current.track?.activeCues?.[0])

    React.useEffect(() => {
      if (chapterElem.current) {
        chapterElem.current.addEventListener('load', trackLoaded)
        chapterElem.current.addEventListener('cuechange', trackChange)

        return () => {
          if (chapterElem.current) {
            chapterElem.current.removeEventListener('load', trackLoaded)
            chapterElem.current.removeEventListener('cuechange', trackChange)
          }
        }
      }
    }, [chapterElem])

    return (
      <VideoOuter>
        <CoreVideo {...props} ref={refCallback}>
          {track && (
            <track
              ref={chapterElem}
              src={track}
              kind="metadata"
              label="Locations"
              srcLang="en"
              default
            />
          )}
        </CoreVideo>
      </VideoOuter>
    )
  }
)

export const VideoOuter = styled.div(
  ({ theme: { fonts, colors } }) => `
  video::-webkit-media-text-track-container {
    display:none;
   }
   video::-webkit-media-text-track-background {
     display:none;
   }
   video::-webkit-media-text-track-display {
     display:none;
   }



   ${cq('Figure')} > ${cq('ControlsContainerTop')}{
    bottom:0;
    right:0;
    background:transparent;
    pointer-events:none;
    ${cq('ControlsRight')}{
      display:none;
    }

  }

  ${cq('Figure')} > ${cq('ControlsContainerBottom')}{


    background:${colors.videoControlsBg};
    display:grid;
    grid-template-columns: 40px auto 50px;

    ${mq[1]} {
      grid-template-columns: 140px auto 125px;
    }
    grid-template-rows: 1;



    ${cq('StyledVideo')}{
      background-color:red !important;
    }

    ${cq('ControlsContainerRow')}{
      display:grid;
      display: grid;
      grid-column: 1 / 4;
      grid-row: 1;
      ${cq('ControlsLeft')}{
        grid-column: 1;
        grid-row: 1;

        ${cq('video-PlaytimeContainer')}{
          display:none;
          ${mq[1]} {
            display:inherit;
          }
        }
      }

      ${cq('ControlsRight')}{
        grid-column: 3;
        grid-row: 1;

       .DraggableSlider{
           opacity:0;
          pointer-events:none;
          transform:rotate(-90deg);
          position:absolute;
          left: -25px;
          bottom: 98px;
          transform-origin:center bottom;
          background:${colors.videoControlsBg};
           border:7px solid ${colors.videoControlsBg};
          border-right:14px solid ${colors.videoControlsBg};
          border-top-right-radius:100px;
          border-bottom-right-radius:100px;
          -webkit-background-clip: padding-box;
          background-clip: padding-box;
          backface-visibility: hidden;

          #filledTrack{
            background:${colors.inputBorderHover};
          }
          #track{
            background:${colors.linkDeactive};
          }

        }
        .video-ctrl-volume:hover{
            .DraggableSlider{
              opacity:1;
              pointer-events:all;
            }
        }

        .video-ctrl-quality, .video-ctrl-volume{
          display:none;
        }

        ${mq[1]} {
          .video-ctrl-quality, .video-ctrl-volume{
            display:inherit;
          }
        }
      }

    }


    ${cq('IconBoundingBox')}{
     padding:7px;
    }

    ${cq('video-PlaytimeContainer')}{
      span{font-size:14px;}
    }

    ${cq('ProgressBarContainer')}{
        grid-column: 2;
        grid-row: 1;

        #thumb{
          display:none;
        }
    }

    ${cq('QualitySelectContainer')}{
      background:${colors.videoControlsBg};
      bottom:41px;
      left:0;
      span{font-size:14px;}

    }
  }
  `
)

export const Navigation = styled.div(``)
