const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
  hostname !== 'localhost'
    ? `${process.env.GATSBY_PREFIX || ''}/api/v1`
    : 'https://gc21-salesform.buhler.cuenect.online/api/v1'

export const apiConfig = {
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://gc21-salesform.buhler.cuenect.online/api/v1'
      : '/api/v1',
  chatUrl: 'https://chat.cuenect.de',
  checkIn: `${apiUri}/users/check-in`,
  me: `${apiUri}/persons/me`,
  appointmentSlots: `${apiUri}/appointments/slots`,
  /*  appointmentSlots: `/mockdata/appointmentSlots.json`, */
  eventParticipation: `${apiUri}/events/participation`,
  appointments: `${apiUri}/appointments`,
  registerEvent: `${apiUri}/events`,

  register: `${apiUri}/appointments`,
  sales: `${apiUri}/sales`,
  topics: `/${
    hostname === 'localhost' ? 'mockData' : '_config'
  }/topics-tree.json`,
  dialogAppointment: `${apiUri}/appointments/feedback`,
}
export const AUTH_ACTIVE = true
