import styled from '@emotion/styled'
import { LocalStorageUtil, useAtoms, useTranslations } from 'cuenect-web-core'
import dayjs from 'dayjs'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {
  AgendaFilter,
  AGENDA_FILTER,
  AGENDA_SHOW_BOOKMARKS,
  Button,
  DateDisplay,
  SieFilter,
  Toggle,
} from '../..'
import { ProgramCategory } from '../../../api/program'
import { mq } from '../../../utility'
import { AnalyticsTransformer } from '../../../utility/analytics'
import { TimezoneSwitch } from '../../molecules/timezoneSwitch'
import {
  useBookmarksContext,
  useSlideInContext,
  useTimezoneContext,
} from '../../../utility'
export interface ProgramHeaderProps {
  dates: dayjs.Dayjs[]
  userTimezone: string
  eventTimezone: string
  selectedDate?: number
  onDateChange?(index: number): void
  timezoneChecked?: boolean
  onTimezoneChange?(checked: boolean): void
  onFilterChange?(filters: string[]): void
  onBookmarksChange?(active: boolean): void
  categories?: ProgramCategory[]
  rooms?: ProgramCategory[]
  subdivision: string
  dateDisplay: DateDisplay
  showFilter?: boolean
  hideBookmark?: boolean
  slug?: string
  resetBookmarksToggle?: boolean
}

export const ProgramHeader: React.FC<ProgramHeaderProps> = ({
  dates,
  selectedDate,
  eventTimezone,
  userTimezone,
  onDateChange,
  timezoneChecked,
  onTimezoneChange,
  onFilterChange,
  onBookmarksChange,
  categories,
  rooms,
  subdivision,
  dateDisplay = DateDisplay.DATE_DISPLAY_BUTTON,
  showFilter = true,
  hideBookmark = false,
  resetBookmarksToggle = false,
  slug = '',
}) => {
  const isStandalone = process.env.GATSBY_STANDALONE

  const t = useTranslations('program')
  const { Heading } = useAtoms()

  const { dispatch } = useSlideInContext()
  const { dispatch: dispatchTimezone } = useTimezoneContext()
  const loadFilter = (sub: string): string[] => {
    const af =
      LocalStorageUtil.getObject<{ [key: string]: string[] }>(AGENDA_FILTER) ||
      {}

    return af[sub] || []
  }

  const [filters, setFilters] = React.useState<string[]>(
    loadFilter(subdivision)
  )

  const [bookmarks, setBookmarks] = React.useState<boolean>(
    LocalStorageUtil.getString(AGENDA_SHOW_BOOKMARKS) === 'true'
  )
  const [selectedBookmarks, setSelectedBookmarks] = React.useState<boolean>(
    LocalStorageUtil.getString(AGENDA_SHOW_BOOKMARKS) === 'true'
  )

  const {
    state: { bookmarks: stateBookmarks },
  } = useBookmarksContext()

  const hasBookmarks = stateBookmarks.length > 0

  React.useEffect(() => {
    onFilterChange && onFilterChange(filters)
  }, [filters])

  React.useEffect(() => {
    onBookmarksChange && onBookmarksChange(selectedBookmarks)
  }, [selectedBookmarks])

  React.useEffect(() => {
    LocalStorageUtil.saveString(
      AGENDA_SHOW_BOOKMARKS,
      selectedBookmarks ? 'true' : 'false'
    )
  }, [selectedBookmarks])

  React.useEffect(() => {
    if (stateBookmarks.length < 1) {
      setSelectedBookmarks(false)
    }
  }, [stateBookmarks])

  React.useEffect(() => {
    if (resetBookmarksToggle) {
      setSelectedBookmarks(false)
    }
  }, [resetBookmarksToggle])

  return (
    <Container>
      {dateDisplay === 'dateDisplayButton' && (
        <LeftContainer>
          {dates.length === 1 && (
            <Heading type="h3">{dates[0].format('dddd, LL')}</Heading>
          )}
          {dates.length > 1 && (
            <DateSelector>
              {dates.map((date, index) => (
                <Button
                  tag
                  key={index}
                  active={index === selectedDate}
                  onClick={() => {
                    onDateChange && onDateChange(index)
                  }}
                >
                  <span className="desktop">{date.format('dddd, MMMM D')}</span>
                  <span className="mobile">{date.format('MMMM Do')}</span>
                </Button>
              ))}
            </DateSelector>
          )}
        </LeftContainer>
      )}

      <RightContainer>
        <FilterContainer>
          {!isStandalone && !hideBookmark && (
            <BookmarkToggle>
              {t('onlyBookmarks')}
              <Toggle
                defaultChecked={selectedBookmarks}
                disabled={stateBookmarks.length < 1 || resetBookmarksToggle}
                onChange={({ currentTarget: { checked } }) => {
                  setSelectedBookmarks(checked)
                }}
              />
            </BookmarkToggle>
          )}
          {userTimezone !== eventTimezone && (
            <TimezoneSwitchContainer>
              <TimezoneSwitch
                userTimezone={userTimezone}
                eventTimezone={eventTimezone}
                onTimezoneChange={selected => {
                  dispatchTimezone({
                    type: 'CHANGE_TIMEZONE',
                    timezoneChecked: selected,
                  })
                }}
                timezoneChecked={timezoneChecked}
              />
            </TimezoneSwitchContainer>
          )}
          {showFilter && (
            <Button
              plain
              small
              icon={SieFilter}
              iconAlign="right"
              active={filters.length > 0}
              onClick={() => {
                dispatch({
                  type: 'OPEN_WITH_COMPONENT',
                  state: {
                    component: AgendaFilter,
                    params: {
                      categories,
                      rooms,
                      subdivision,
                      onChange: (filter: string[]) => {
                        setFilters(filter)
                      },
                    },
                  },
                })
                AnalyticsTransformer.customEvent(['slidein', `filter`])
              }}
            >
              {process.env.GATSBY_STANDALONE
                ? process.env.GATSBY_LANGUAGE === 'de'
                  ? slug === 'guided-tour'
                    ? 'Sprache'
                    : 'Themenfilter'
                  : slug === 'guided-tour'
                  ? 'Language'
                  : 'Filter topics'
                : t(
                    slug === 'guided-tour' ? 'languageFilter' : 'programFilter'
                  )}{' '}
              {filters.length ? `(${filters.length})` : ''}
            </Button>
          )}
        </FilterContainer>
      </RightContainer>
    </Container>
  )
}

const BookmarkToggle = styled.div({
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  marginBottom: '40px',
  '& >label': {
    marginLeft: '20px',
  },
})

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  [mq[2]]: {},
  margin: '3rem 0',
}))

const DateSelector = styled(PerfectScrollbar)({
  display: 'flex',
  maxWidth: '90vw',
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  '& > button': {
    marginRight: '20px',
    maxWidth: '40%',
    whiteSpace: 'nowrap',

    '.desktop': {
      display: 'none',
    },
    [mq[2]]: {
      '.mobile': {
        display: 'none',
      },
      '.desktop': {
        display: 'inherit',
      },
    },
  },
})

const FilterContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  [mq[2]]: { alignItems: 'flex-end' },
}))

const TimezoneSwitchContainer = styled.div({
  marginTop: '-20px',
  marginBottom: '20px',
})

const LeftContainer = styled.div({
  flex: 80,
})

const RightContainer = styled.div({
  flex: 20,
  marginBottom: '1rem',
  display: 'flex',
  [mq[2]]: { marginBottom: 0, justifyContent: 'flex-end' },
})
