import styled from '@emotion/styled'
import React from 'react'
import { Button, BueClose } from '../..'
import {
  useFullscreenContext,
  useModalContext,
  useVodContext,
} from '../../../utility'
type DivProps = JSX.IntrinsicElements['div']
export interface FakeFullscreenProps extends DivProps {
  isFullscreen?: boolean
  showClose?: boolean
  hidden?: boolean
}

export const FakeFullscreen: React.FC<FakeFullscreenProps> = ({
  isFullscreen,
  showClose,
  children,
  hidden,
}) => {
  const { dispatch: changeFullscreen } = useFullscreenContext()
  const { dispatch: vodDispatch } = useVodContext()
  const { dispatch: modalDispatch } = useModalContext()

  React.useEffect(() => {
    changeFullscreen({
      type: 'FULLSCREEN_CHANGE',
      isFullscreen: isFullscreen && !hidden,
    })
  }, [isFullscreen])

  return (
    <FakeFullscreenContainer isFullscreen={isFullscreen} hidden={hidden}>
      {showClose && (
        <Button
          icon={BueClose}
          plain
          small
          iconAlign="right"
          onClick={() => {
            vodDispatch({
              type: 'HIDE_VOD',
            })
            modalDispatch({
              type: 'HIDE_MODAL',
            })
          }}
        >
          CLOSE
        </Button>
      )}
      {children}
    </FakeFullscreenContainer>
  )
}

export const FakeFullscreenContainer = styled.div<
  Pick<FakeFullscreenProps, 'isFullscreen'>
>(({ theme: { colors, grid }, isFullscreen, hidden }) => ({
  display: isFullscreen ? (hidden ? 'none' : 'block') : 'inline',
  position: isFullscreen ? 'fixed' : 'static',
  left: 0,
  top: 0,
  // the 0px are needed for the transition, because it doesn't work with auto
  // since overflow is set to 'visible' by default, it has no effect
  width: isFullscreen ? '100vw' : '100px',
  // the 100% instead of 100vh are needed for iPhones because otherwise it would include the space behind
  // the bottom browser bar (which may overlay something that is supposed to be visible like video controls)
  height: isFullscreen ? '100%' : '100px',
  transition: 'width .25s ease-in, height .25s ease-in',
  backgroundColor: 'black',
  zIndex: 3000,
  '& > button': {
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 999,
  },
}))
