import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import { ProgramEvent } from '../api/program'

interface Languages {
  [language: string]: string
}
/* interface EventPhase {
  name: string
  time: Moment.moment
} */
interface EventConfig {
  utcOffset: number
  vodCanPlayOffset: number
  timezone: string
  timezoneShort: string
  countdownStart: dayjs.Dayjs
  countdownEnd: dayjs.Dayjs
  sources: Languages
  redirects: Languages
  regForm: Languages
  countdown?: string
  participationWarning: number
  conversationAvailable: number
  conversationCancable: number
  testSession: string
  postEvent: dayjs.Dayjs
  postEventFirst: dayjs.Dayjs
  postEventSecond: dayjs.Dayjs
  postEventAppointments: dayjs.Dayjs

  /*   phases: Array<EventPhase>
   */
}
const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
  hostname !== 'localhost'
    ? '/api/v2'
    : 'https://smart-buildings-2021.siemens.cuenect.online/api/v2'

export const eventConfig: EventConfig = {
  utcOffset: 60,
  vodCanPlayOffset: 5,
  timezone: 'Europe/Berlin',
  timezoneShort: 'CEST',
  participationWarning: 5,
  countdownStart: TimeUtil.getUtc('2021-06-20T20:00:00Z'),
  countdownEnd: TimeUtil.getUtc('2021-08-21T07:30:00Z'),
  postEvent: TimeUtil.getUtc('2021-06-25T15:15:00Z'),
  postEventFirst: TimeUtil.getUtc('2021-06-25T13:00:00Z'),
  postEventSecond: TimeUtil.getUtc('2021-06-25T13:30:00Z'),
  postEventAppointments: TimeUtil.getUtc('2021-06-25T13:00:00Z'),
  countdown: '/countdown.mp4',
  conversationAvailable: 30,
  conversationCancable: 5,
  testSession: 'https://meet.virtualevent.siemens.com/testsession',
  sources: {
    de: process.env.GATSBY_LOCAL_DATA
      ? '/mockData/events.json'
      : `${apiUri}/events.json`,
    en: process.env.GATSBY_LOCAL_DATA
      ? '/mockData/events.json'
      : `${apiUri}/events.json`,
  },
  redirects: {
    de: '',
    en: '',
  },
  regForm: {
    de:
      'https://new.siemens.com/global/en/company/fairs-events/smart-buildings-connect.html#Registration',
    en:
      'https://new.siemens.com/global/en/company/fairs-events/smart-buildings-connect.html#Registration',
  },
}

interface IDialogConfig {
  customerrequest: string[]
  customerproject: string[]
}

export const dialogConfig: IDialogConfig = {
  customerrequest: [
    'appointmentSales', // call_visit
    'specialRequest', // specific_information
    'noFollowUp', // no_follow_activity_agreed
    'noCustomerParticipation',
  ],
  customerproject: [
    'noProjectPlanned',
    'ongoingProject',
    'shortTerm',
    'midTerm',
    'longTerm',
  ],
}

export const isPostEventFirst = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventFirst)
export const isPostEventSecond = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventSecond)
export const isPostEvent = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEvent)
export const isPostEventAppointments = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventAppointments)

export interface EventBreakEvent
  extends Pick<
    ProgramEvent,
    'title' | 'start' | 'end' | 'categories' | 'isBreak'
  > {}
interface EventBreaks {
  slug: string
  events: EventBreakEvent[]
}

export const eventBreaks: EventBreaks[] = [
  /* {
    slug: 'auditorium-de',
    events: [
      {
        isBreak:true,
        title: 'Mittagspause',
        start: '2021-04-12T08:15:00+00:00',
        end: '2021-04-12T08:40:00+00:00',
      },
    ],
  },
  {
    slug: 'auditorium-en',
    events: [
      {
        isBreak:true,
        title: 'Lunchbreak',
        start: '2021-04-12T08:15:00+00:00',
        end: '2021-04-12T08:40:00+00:00',
      },
    ],
  }, */
]
