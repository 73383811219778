import { keyframes } from '@emotion/react'
import React from 'react'
import { LoadingIndicator } from '..'

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  scale,
  ...rest
}) => {
  return <LoadingIndicator {...rest} scale={scale ? scale : 1} />
}
