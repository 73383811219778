import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { mq } from '../../../utility'
import { Button, BueClose } from '../../atoms'
import { useSlideInContext } from '../../../utility'

export interface SlideInProps {
  visible?: boolean
  standalone?: boolean
}

export const SlideIn = () => {
  const {
    state: { showSlideIn, component, params },
    dispatch: dispatchSlideIn,
  } = useSlideInContext()
  const { t } = useTranslation('core')
  const isStandalone = process.env.GATSBY_STANDALONE
  const componentRef = React.useRef()

  const closeSlideIn = () => {
    try {
      componentRef?.current.closeSlideIn()
    } catch (error) {}
    dispatchSlideIn({ type: 'HIDE_SLIDEIN' })
  }

  return (
    <div
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1049;
        pointer-events: none;
      `}
    >
      <SlideInDim visible={showSlideIn} onClick={() => closeSlideIn()} />
      <SlideInContainer standalone={isStandalone} visible={showSlideIn}>
        <CloseButtonHolder>
          <Button
            icon={BueClose}
            plain
            small
            iconAlign="right"
            onClick={() => closeSlideIn()}
          >
            {t('close')}
          </Button>
        </CloseButtonHolder>
        <SlideInContent>
          {component &&
            React.createElement(component, { ...params, ref: componentRef })}
        </SlideInContent>
      </SlideInContainer>
    </div>
  )
}

const SlideInDim = styled.div<SlideInProps>(
  ({ theme: { colors }, visible }) => `
  width:100%;
  height:100%;
  background:${rgba(colors.dim, 0.7)};
  opacity:${visible ? 1 : 0};
  pointer-events:${visible ? 'all' : 'none'};
  transition:opacity .25s;
`
)
const SlideInContainer = styled.div<SlideInProps>(
  ({ theme: { colors }, visible, standalone }) => `
  position:fixed;
  box-sizing: border-box;
  top:${standalone ? '150px' : 0};
  height:${standalone ? 'calc(100vh - 200px)' : '100vh'};
  right:0;
  width:100vw;
  background:${colors.bodyBackground};
  padding:42px;
  pointer-events:all;
  transform:translate3d(${visible ? '0' : '100vw'}, 0, 0);
  ${[mq[2]]}{
    width:50vw;
    transform:translate3d(${visible ? '0' : '50vw'}, 0, 0);
  }
  transition:transform .25s;
  display:flex;
  flex-direction:column;
`
)
const CloseButtonHolder = styled.div(
  ({ theme: { fonts } }) => `
  display:flex;
  justify-content:flex-end;
  button{
    font-family:${fonts.fontFamily};
  }
`
)

const SlideInContent = styled.div(
  () => `
  margin-top:100px;
  flex:2;
`
)
