import styled from '@emotion/styled'
import { Talk } from 'cuenect-web-core'
import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph } from '../..'
import { Button } from '../../atoms'
import { ProgramService } from '../../../api'
import { useReservationContext, useSlideInContext } from '../../../utility'

interface AgendaReserveProps extends Pick<Talk, 'id' | 'title'> {
  remove?: boolean
  selectedDate?: number
}

export const AgendaReserve: React.FC<AgendaReserveProps> = ({
  id,
  title,
  remove,
  selectedDate = 0,
}) => {
  const { dispatch: dispatchSlideIn } = useSlideInContext()
  const { t } = useTranslation('program')

  const {
    state: { reservations },
    dispatch: dispatchReservation,
  } = useReservationContext()

  const reserveSeat = async (eventId: string) => {
    const succ = await ProgramService.reserveEvent(eventId)

    if (succ.status === 204) {
      dispatchReservation({
        type: 'ADD_RESERVATION',
        id,
        selectedDate,
      })
      dispatchSlideIn({ type: 'HIDE_SLIDEIN' })
    } else {
      console.error(succ)
      alert('ERROR')
    }
  }

  const cancelSeat = async (eventId: string) => {
    const succ = await ProgramService.cancelEvent(eventId)
    if (succ.status === 204) {
      dispatchReservation({
        type: 'REMOVE_RESERVATION',
        id,
        selectedDate,
      })
      dispatchSlideIn({ type: 'HIDE_SLIDEIN' })
    } else {
      console.error(succ)
      alert('ERROR')
    }
  }

  return (
    <Container>
      <Heading type="h4">
        {/* remove */}
        {t(`participations.${remove ? 'cancel' : 'confirm'}.header`)}
      </Heading>
      <Paragraph>
        {parse(
          t(`participations.${remove ? 'cancel' : 'confirm'}.copy`).replace(
            '{title}',
            title
          )
        )}
      </Paragraph>
      <ButtonHolder>
        <Button
          secondary
          onClick={() => dispatchSlideIn({ type: 'HIDE_SLIDEIN' })}
        >
          {t(`participations.${remove ? 'cancel' : 'confirm'}.cancel`)}
        </Button>
        <Button onClick={() => (!remove ? reserveSeat(id) : cancelSeat(id))}>
          {t(`participations.${remove ? 'cancel' : 'confirm'}.confirm`)}
        </Button>
      </ButtonHolder>
    </Container>
  )
}
const Container = styled.div(
  ({ theme: { grid } }) => `
  display:flex;
  flex-direction:column;
  justify-content:stretch;
  align-items:stretch;
  height:100%;
`
)

const ButtonHolder = styled.div(
  ({ theme: { grid } }) => `
  display:flex;
  justify-content:space-between;
  button{
    width:45%;
    text-align:center;
    display: flex;
    justify-content: center;
  }
`
)
