import { AxiosResponse } from 'axios'
import { LocalStorageUtil } from 'cuenect-web-core'
import { HttpService } from './http'
import { apiConfig, AUTH_ACTIVE } from '../config'

export interface LocalData {
  person: UserData
}
export interface UserData {
  salutation: {
    de: string
    en: string
  }
  address?: {
    de: string
    en: string
  }
  firstname: string
  lastname: string
  country: string | null
}

export class Auth {
  private static isBrowser: boolean = typeof window !== 'undefined'
  public static isLocalhost: boolean =
    typeof window !== 'undefined'
      ? window.location.hostname === 'localhost'
      : false

  public static isAuthenticated(): boolean {
    if (!Auth.isBrowser) {
      return false
    }

    return LocalStorageUtil.getString('isLoggedIn') === 'true'
  }

  public static async authenticate(token?: string): Promise<boolean> {
    if (process.env.GATSBY_BYPASS_AUTH === 'true') {
      return true
    }
    if (!token) {
      return false
    }
    const response = await AuthService.checkin(token)

    if (!response) {
      return false
    }

    const {
      status,
      data: {
        analytics: { userId, customDimensions },
        person,
      },
    } = response

    if (status !== 200) {
      return false
    }

    LocalStorageUtil.saveString('isLoggedIn', 'true')
    LocalStorageUtil.saveData('user', {
      token,
      userId,
      customDimensions: customDimensions[0],
      person,
    })

    return true
  }

  public static async reAuthenticate(): Promise<boolean> {
    const { token } = LocalStorageUtil.getObject('user')

    return await Auth.authenticate(token)
  }

  public static async getUserData(): Promise<UserData> {
    return await AuthService.me()
  }
}

export class AuthService {
  public static async checkin(token: string) {
    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.checkIn,
      data: { token },
      config: { withCredentials: true },
    })

    return result
  }

  public static async me(): Promise<
    UserData | AxiosResponse<UserData> | false
  > {
    if (Auth.isLocalhost) {
      // Mockdata
      return {
        salutation: {
          de: 'Sehr geehrter Herr',
          en: 'Dear Mr.',
        },
        address: {
          de: 'Herr',
          en: 'Mr.',
        },
        firstname: 'Max',
        lastname: 'Mustermann',
        country: 'Germany',
      }
    }

    const result: false | AxiosResponse<UserData> = await HttpService.get({
      url: apiConfig.me,
      config: { withCredentials: true },
    })

    return result
  }
}
