import styled from '@emotion/styled'
import { AspectRatioContainer } from 'cuenect-web-core'
import { BooleanArraySupportOption } from 'prettier'
import React from 'react'
import { FakeFullscreen, Video } from '../..'
import { useDimensions } from '../../../utility'
import { useVodContext } from '../../../utility'
interface MobileVodVideoProps {
  src?: string | null
  trackingTitle?: string | null
  playerName?: string | null
  showMobile?: boolean
}

export const MobileVodVideo: React.FC<MobileVodVideoProps> = ({
  src,
  trackingTitle,
  playerName,
  showMobile,
}) => {
  const [, boundingBox] = useDimensions()
  const videoRef = React.useRef()
  const { dispatch: openVod } = useVodContext()

  const fsChange = () => {
    openVod({ type: 'HIDE_VOD' })
  }

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('webkitendfullscreen', fsChange)
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('webkitendfullscreen', fsChange)
      }
    }
  }, [videoRef])

  React.useEffect(() => {
    if (videoRef.current) {
      /*  videoRef.current.requestFullscreen() */
    }
  }, [src])

  React.useEffect(() => {
    if (!showMobile) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [showMobile])

  return (
    <FakeFullscreen
      isFullscreen={true}
      showClose={true}
      hidden={!src || !showMobile}
    >
      <MobileVodContainer>
        <AspectRatioContainer xRatio={16} yRatio={9} boundingBox={boundingBox}>
          <Video
            autoPlay
            playerName={playerName}
            ref={videoRef}
            src={src}
            trackingTitle={trackingTitle || ''}
          />
        </AspectRatioContainer>
      </MobileVodContainer>
    </FakeFullscreen>
  )
}
const MobileVodContainer = styled.div(`
  display:flex;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;

  video{
    width:100%;
    height:100%;
  }
`)
