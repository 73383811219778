import { AxiosResponse } from 'axios'
import { TimeUtil } from 'cuenect-web-core'
import { SalesFormData } from '../components'
import { HttpService } from './http'
import { apiConfig } from '../config'
export interface SalesUser {
  email: string
  firstName: string
  lastName: string
  companyName: string
  CRMContact: string
  companyCountry: string
}
export interface SalesResource {
  email: string
  start: string
  end: string
  title: string
}
interface SalesPost {
  version: string
  description?: string
  start: string
  end: string
  host: SalesUser
  participants: SalesUser[]
  tags: string[]
}

export class SalesService {
  public static getTags = ({
    event,
    meetinglanguage,
  }: SalesFormData): string[] => {
    // below, there are IIFEs that add tags depending on the given arguments

    const tagsWithEvent = ((tags: string[]) => {
      if (event === '204') {
        return [...tags, 'Model: Services']
      }

      return []
    })([])

    const tagsWithLanguage = ((tags: string[]) => [
      ...tags,
      `#${meetinglanguage}`,
    ])(tagsWithEvent)

    return tagsWithLanguage
  }

  public static parseFormRequest(data: SalesFormData, tags: string[]) {
    const {
      start,
      end,
      hostEmail,
      hostFirstname,
      hostLastname,
      operatingCompany,
      country,
      meetinglanguage,
      emaillanguage,
      note,
      timezone,
      event,
      buehlerUser,
    } = data

    return {
      version: 1,
      start,
      end,
      host: {
        email: hostEmail,
        firstName: hostFirstname,
        lastName: hostLastname,
        language: emaillanguage,
        description: null,
        timeZone: timezone,
        buehlerUser,
      },
      participants: SalesService.getParticipants(data),
      resources: SalesService.getRecources(data),
      description: note,
      services: [Number(event)],
      tags,
    }
  }

  public static getParticipants = ({
    email,
    gender,
    firstName,
    lastName,
    emaillanguage,
    CRMContact,
    companyName,
    companyCountry,
  }: SalesFormData) => {
    const participants = []
    email.forEach((value, index) => {
      if (value) {
        participants.push({
          email: value,
          address: gender[index],
          firstName: firstName[index],
          lastName: lastName[index],
          language: emaillanguage,
          fields: {
            companyName: companyName[index],
            companyCountry: companyCountry[index],
            CRMContact: CRMContact[index],
          },
        })
      }
    })

    return participants
  }

  public static getRecources = ({
    expertEmail,
    expertFirstname,
    expertLastname,
    expertStart,
    expertEnd,
    expertBuehlerUser,
    emaillanguage,
    start,
    end,
    timezone,
  }: SalesFormData) => {
    const resources = []
    expertEmail?.forEach((value, index) => {
      const hasSameTime: boolean =
        TimeUtil.getUtc(start).isSame(TimeUtil.getUtc(expertStart[index])) &&
        TimeUtil.getUtc(end).isSame(TimeUtil.getUtc(expertEnd[index]))

      if (value) {
        resources.push({
          email: value,
          firstName: expertFirstname[index],
          lastName: expertLastname[index],
          buehlerUser: expertBuehlerUser[index],
          start: hasSameTime ? null : expertStart[index],
          end: hasSameTime ? null : expertEnd[index],
          timeZone: timezone,
          language: emaillanguage,
        })
      }
    })

    return resources
  }

  public static async sendForm(data: SalesFormData, tags: string[]) {
    console.log('parsedData OLD', data)

    const parsedData = SalesService.parseFormRequest(data, tags)
    console.log('parsedData', parsedData)

    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.sales,
      data: parsedData,
    })

    return result

    /*  console.log('parsedDataOLD', {
      region: 'en_US',
      tags: ['#CN:preferred'],
      start: '2021-08-06T08:00:00Z',
      end: '2021-08-06T10:00:00Z',
      participants: [
        {
          address: 'Mr.',
          email: 'thorbenschmitt@me.com',
          firstName: 'Thorben',
          lastName: 'Schmitt',
          fields: {
            companyName: 'cue',
            companyCountry: 'Germany',
            CRMContact: '123456',
          },
        },
      ],
      host: {
        email: 'example@buhlergroup.com',
        firstName: 'Thorben',
        lastName: 'Schmitt',
        description: '',
        buehlerUser: 'xxxxxxx',
      },
      resources: [
        {
          start: '',
          end: '',
          email: 'expert@gmail.com',
          firstName: 'Thorben',
          lastName: 'Schmitt',
          startEndTime: null,
          description: '',
        },
      ],
      description: 'Test Appointment',
    }) */
    /*  const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.sales,
      data: parsedData,
    })

    return result */
  }
}
/* {"region":"en_US","tags":["#CN:preferred"],"start":"2021-08-06T08:00:00Z","end":"2021-08-06T10:00:00Z","participants":[{"address":"Mr.","email":"thorbenschmitt@me.com","firstName":"Thorben","lastName":"Schmitt","fields":{"companyName":"cue","companyCountry":"Germany","CRMContact":"123456"}}],"host":{"email":"example@buhlergroup.com","firstName":"Thorben","lastName":"Schmitt","description":"","buehlerUser":"xxxxxxx"},"resources":[{"start":"","end":"","email":"expert@gmail.com","firstName":"Thorben","lastName":"Schmitt","startEndTime":null,"description":""}],"description":"Test Appointment"} */
