import { GenIcon, IconBaseProps } from 'react-icons'
export const BueClock = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M0,12C0,18.6,5.4,24,12,24c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12C5.4,0,0,5.4,0,12z M22.6,12c0,5.8-4.8,10.6-10.6,10.6	C6.1,22.6,1.4,17.8,1.4,12C1.4,6.2,6.2,1.4,12,1.4C17.9,1.4,22.6,6.2,22.6,12z M10.8,11.6c0,0.1,0,0.2,0,0.3c0,0.7,0.5,1.3,1.3,1.3	c0.4,0,0.8-0.2,1-0.5c0.1-0.1,0.1-0.2,0.1-0.2h0c2.6,0,5.2,0,7.8,0h0.2c0.1,0,0.2-0.1,0.2-0.2v-0.6c0-0.1-0.1-0.2-0.2-0.2h-0.2	c-2.6,0-5.1,0-7.7,0h0c0,0,0-0.1-0.1-0.2c-0.2-0.4-0.6-0.8-1.2-0.8c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1	c-0.9-0.9-1.9-1.8-2.8-2.7C8.3,7.6,8,7.3,7.7,7C7.6,6.9,7.5,6.9,7.4,7C7.3,7.1,7.1,7.3,7,7.4C6.9,7.5,6.9,7.6,7,7.6	c1.2,1.2,2.5,2.4,3.7,3.6C10.8,11.4,10.8,11.5,10.8,11.6L10.8,11.6z M11.4,20.5c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6	c0-0.3-0.3-0.6-0.6-0.6C11.6,19.9,11.4,20.2,11.4,20.5L11.4,20.5z M15.6,4.6c0,0.3,0.2,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6	c0-0.3-0.3-0.6-0.6-0.6C15.9,4,15.6,4.3,15.6,4.6L15.6,4.6z M11.5,3.5c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6	c0-0.3-0.3-0.6-0.6-0.6C11.7,2.9,11.5,3.2,11.5,3.5z M5.2,7.8c0-0.3-0.3-0.6-0.6-0.6C4.3,7.2,4,7.5,4,7.8c0,0.3,0.3,0.6,0.6,0.6	C5,8.4,5.2,8.1,5.2,7.8L5.2,7.8z M8.4,19.4c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6	C8.2,20,8.4,19.7,8.4,19.4z M18.7,7.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C19,7.2,18.7,7.5,18.7,7.8	z M18.8,16.2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6C19.1,15.6,18.8,15.9,18.8,16.2L18.8,16.2z M4.1,12	c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6C3.9,12.6,4.1,12.3,4.1,12z M5.3,16.3c0-0.3-0.3-0.6-0.6-0.6	c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6C5,16.8,5.2,16.6,5.3,16.3z M8.4,4.7c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6	c0,0.3,0.3,0.6,0.6,0.6C8.1,5.3,8.4,5,8.4,4.7z M15.7,19.4c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6	C15.9,18.8,15.7,19,15.7,19.4L15.7,19.4z',
        },
        child: [],
      },
    ],
  })(props)
