/* tslint-disable */
import { LocalStorageUtil } from 'cuenect-web-core'
import React, { useEffect, useRef } from 'react'
import { fairtouchConfig } from '../config'

declare global {
  interface Window {
    // tslint:disable-next-line: no-any
    Matomo: any
    // tslint:disable-next-line: no-any
    _paq: [string, ...any[]][]
  }
}

export interface TrackingData {
  type: 'media' | 'event'
  name?: string
  src?: string
  action?: string
  category?: string
  data?: {
    title?: string
    progressInSeconds?: number
    totalLengthInSeconds?: number
  } | null
  context?: {
    uid?: string
    topics?: Array<string>
  }
  source?: string | null
}

export class AnalyticsTransformer {
  // tslint:disable-next-line: no-any
  private MA: any
  // tslint:disable-next-line: no-any
  private tracker: any
  // tslint:disable-next-line: typedef
  private playerName = 'Default Player'
  private currentData: { src: string; title: string } | null = null
  public static setUserData() {
    const { userId, customDimensions } =
      LocalStorageUtil.getObject('user') || {}

    try {
      if (!window._paq) {
        window._paq = []
      }

      if (userId) {
        window._paq.push(['setUserId', userId])
      }

      if (customDimensions) {
        window._paq.push(['setCustomDimension', 1, customDimensions])
      }
      window._paq.push(['trackPageView'])
    } catch (error) {
      console.warn(error)
    }
  }

  // tslint:disable-next-line: no-any
  public static customEvent(params: any = []) {
    console.debug('Event', ['trackEvent', ...params])
    try {
      window._paq.push(['trackEvent', ...params])
    } catch (error) {
      console.debug(error)
    }
  }

  public constructor() {
    if (typeof window !== 'undefined' && window.Matomo) {
      this.MA = window.Matomo.MediaAnalytics
    }
  }
  public transformTrackingEvent = (data: TrackingData) => {
    const { type } = data
    if (type === 'event') {
      this.transformEvent(data)
    }
    if (type === 'media') {
      this.transformMediaEvent(data)
    }
  }

  public setPlayername = (playerName: string) => {
    console.debug(`Set Playername ${playerName}`)

    this.playerName = playerName
  }

  private mediaTrackLoadedmetadata = ({
    target,
  }: {
    target: HTMLVideoElement
  }) => {
    this.transformMediaEvent({
      name: 'start',
      type: 'media',
      src: this.currentData.src,
      data: {
        title: this.currentData.title,
        totalLengthInSeconds: target.duration,
      },
    })
  }
  private mediaTrackPlay = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'play',
    })
  }
  private mediaTrackPause = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'pause',
    })
  }

  private mediaTrackSeeked = ({ target }: { target: HTMLVideoElement }) => {
    this.transformMediaEvent({
      type: 'media',
      name: 'seeked',
      data: {
        progressInSeconds: target.currentTime,
        totalLengthInSeconds: target.duration,
      },
    })
  }

  private mediaTrackTimeupdate = ({ target }: { target: HTMLVideoElement }) => {
    this.transformMediaEvent({
      type: 'media',
      name: 'timeupdate',
      data: {
        progressInSeconds: target.currentTime,
        totalLengthInSeconds: target.duration,
      },
    })
  }

  private mediaTrackEnded = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'ended',
    })
  }

  public bindMediaTracking = (
    ref: HTMLVideoElement,
    args: { src: string; title: string } | null
  ) => {
    console.debug('Bind Media Analytics', args)
    this.currentData = args
    ref.addEventListener('loadedmetadata', this.mediaTrackLoadedmetadata)
    ref.addEventListener('play', this.mediaTrackPlay)
    ref.addEventListener('pause', this.mediaTrackPause)
    ref.addEventListener('ended', this.mediaTrackEnded)
    ref.addEventListener('seeked', this.mediaTrackSeeked)
    ref.addEventListener('timeupdate', this.mediaTrackTimeupdate)
  }

  public unbindMediaTracking = (ref: HTMLVideoElement) => {
    if (!ref) {
      return
    }
    console.debug('Unbind Media Analytics')
    this.currentData = null
    ref.removeEventListener('loadedmetadata', this.mediaTrackLoadedmetadata)
    ref.removeEventListener('play', this.mediaTrackPlay)
    ref.removeEventListener('pause', this.mediaTrackPause)
    ref.removeEventListener('ended', this.mediaTrackEnded)
    ref.removeEventListener('seeked', this.mediaTrackSeeked)
    ref.removeEventListener('timeupdate', this.mediaTrackTimeupdate)
  }

  transformEvent = ({ category, action, data }: TrackingData) => {
    const trackData = [`${category}`, `${action}`, `${JSON.stringify(data)}`]
    console.debug('Event', ['trackEvent', ...trackData])

    window._paq.push(['trackEvent', ...trackData])
  }

  transformMediaEvent = (input: TrackingData) => {
    const { name, src, data, context, source = null } = input
    // console.debug("MEDIA", input)
    const { topics: [altTitle] = [] } = context || {}
    const {
      title = '',
      totalLengthInSeconds = 0,
      progressInSeconds = 0,
    } = data || { title: '', totalLengthInSeconds: 0, progressInSeconds: 0 }

    if (!this.MA) {
      this.MA = window?.Matomo?.MediaAnalytics
    }

    if (!this.MA) {
      return false
    }
    switch (name) {
      case 'start':
      case 'load':
        if (source) {
          this.playerName = source
        }
        this.tracker = new this.MA.MediaTracker(
          this.playerName,
          this.MA.mediaType.VIDEO,
          src
        )
        this.tracker.setMediaTitle(
          `${title || altTitle || src}${
            context ? `||${JSON.stringify({ ...context })}` : ''
          }`
        )
        this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
        this.tracker.play() // need to come from showroom

        break
      default:
        if (!this.tracker) {
          console.warn('Matomo Media was not initialized')

          return false
        }
        switch (name) {
          case 'play':
            this.tracker.play()
            break
          case 'pause':
            this.tracker.pause()
            break
          case 'ended':
            this.tracker.finish()
            break
          case 'timeupdate':
            this.tracker.setMediaProgressInSeconds(progressInSeconds)
            this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
            this.tracker.update()
            break
          case 'seeked':
            this.tracker.seekStart()
            this.tracker.setMediaProgressInSeconds(progressInSeconds)
            this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
            this.tracker.seekFinish()
            break
          default:
            return
        }
    }
  }
}

export const LoadAnalytics = () => {
  let tracking: AnalyticsTransformer

  useEffect(() => {
    tracking = new AnalyticsTransformer()
    window.removeEventListener('message', listenFrameEvent)

    window.addEventListener('message', listenFrameEvent)

    return () => {
      window.removeEventListener('message', listenFrameEvent)
    }
  }, [])

  const listenFrameEvent = frameEvent => {
    const { data, origin } = frameEvent
    // Wrap rootless object into analytics:{}
    const { analytics, action } =
      data.type === 'event' ? { analytics: data } : data
    const source = origin === fairtouchConfig.uri ? 'Fairtouch' : 'Showroom'

    if (analytics) {
      if (tracking) {
        tracking.transformTrackingEvent({ ...analytics, source })
      }
    }
  }

  return <></>
}
