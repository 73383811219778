"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureFlags = void 0;
exports.featureFlags = {
    phase1: process.env.GATSBY_FF_PHASE1 === 'true',
    phase1b: process.env.GATSBY_FF_PHASE1B === 'true',
    phase2: process.env.GATSBY_FF_PHASE2 === 'true',
    phase2b: process.env.GATSBY_FF_PHASE2B === 'true',
    phase3: process.env.GATSBY_FF_PHASE3 === 'true',
    roomFilters: process.env.GATSBY_FF_ROOM_FILTERS === 'true',
};
