import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import React from 'react'
import { TimepickerProps } from './timepicker'
import { TimepickerEntry } from './timepickerEntry'

interface TimepickerListProps
  extends Pick<TimepickerProps, 'value' | 'min' | 'max' | 'minuteSteps'> {}

interface TimepickerHoursProps extends TimepickerListProps {
  onChange(val: dayjs.Dayjs): void
  visible?: boolean
}

export const TimepickerHours: React.FC<TimepickerHoursProps> = ({
  value,
  min,
  max,
  minuteSteps = 15,
  onChange,
  visible = true,
}) => {
  const [currentValue, setCurrentValue] = React.useState<dayjs.Dayjs>(value)

  const list = GenerateList({
    value,
    min,
    max,
    minuteSteps,
  })

  const updateValue = val => {
    setCurrentValue(val)
    onChange && onChange(val)
  }

  React.useEffect(() => setCurrentValue(value), [value])

  const onSelect = (val: dayjs.Dayjs) => {
    setCurrentValue(val)
  }

  return (
    <TimepickerListContainer style={{ display: visible ? 'block' : 'none' }}>
      <TimepickerArrow />
      <TimepickerListInner>
        <TimepickerList>
          {list.map((time, key) => (
            <TimepickerEntry
              key={key}
              visible={visible}
              selected={currentValue.format('HH:mm') === time.format('HH:mm')}
              value={time}
              onSelect={val => updateValue(val)}
            />
          ))}
        </TimepickerList>
      </TimepickerListInner>
    </TimepickerListContainer>
  )
}

const TimepickerListContainer = styled.div(
  ({ theme: { colors } }) => `
    position:absolute;
    top:65px;
    left:50%;
    margin-left:-50px;
    max-height:30vh;
    background:${colors.bodyBackground};
    z-index:99;

  `
)

const TimepickerListInner = styled.div(
  ({ theme: { colors } }) => `
    height:30vh;
    overflow-y:auto;
    background:${colors.inputBackground};
    border:1px solid ${colors.inputBorder};
  `
)

const TimepickerArrow = styled.div(
  ({ theme: { colors } }) => `
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent ${colors.inputBorder} transparent;
  left:50%;
  position:absolute;margin-left:-10px;top:-10px;

  `
)

const TimepickerList = styled.ul(
  ({ theme: { colors } }) => `
    list-style-type: none;
    margin:0;
    padding:0;
    margin-right:5px;
  `
)

const GenerateList = ({
  value,
  min,
  max,
  minuteSteps,
}: TimepickerListProps): dayjs.Dayjs[] => {
  const startValue: dayjs.Dayjs = (value || dayjs(value))
    .hour(min ? min.hour() : 0)
    .minute(min ? min.minute() : 0)
    .second(0)
  const endValue: dayjs.Dayjs = (value || dayjs(value))
    .hour(max ? max.hour() : 23)
    .minute(max ? max.minute() : 59)
    .second(59)

  let loopTime = startValue
  const timeList: dayjs.Dayjs[] = []

  if (!Number.isInteger(minuteSteps)) {
    return timeList
  }

  while (loopTime.isBefore(endValue)) {
    timeList.push(loopTime)
    loopTime = loopTime.add(minuteSteps, 'minutes')
  }

  return timeList
}
