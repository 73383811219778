import styled from '@emotion/styled'
import { LocalStorageUtil } from 'cuenect-web-core'
import React from 'react'
import { IProgramEntry, ProgramEntry } from '../../molecules/programEntry'
import { ProgramHeader, ProgramHeaderProps } from '../programHeader'
import { AGENDA_TOGGLE, Heading } from '../..'
export interface ProgramModuleProps {
  header: ProgramHeaderProps
  entries: IProgramEntry[][]
  toggleSession?: string
  hideTestSession?: boolean
}

export const ProgramModule: React.FC<ProgramModuleProps> = ({
  entries: entriesProps,
  header: headerProps,
  toggleSession = '',
  hideTestSession = false,
}) => {
  const currentToggle: string[] =
    LocalStorageUtil.getObject<string[]>(AGENDA_TOGGLE) || []
  const indexToUse = (length: number, selectedDate?: number) => {
    // if selectedDate is not provided, just show the first page
    if (!selectedDate) {
      return 0
    }

    // it may occur that in one time zone, the events are spread across n days but in another time zone it's n-1 days
    // this would lead to an index out of bounds error. To avoid this, the last page of the n-1 days time zone is shown
    // in this case
    if (selectedDate > length) {
      return length - 1
    }

    return selectedDate
  }

  const programEntriesToUse =
    entriesProps[indexToUse(entriesProps.length, headerProps.selectedDate)]

  if (headerProps.dateDisplay === 'dateDisplayGroup') {
    //console.log('GROUP EM', headerProps)
  }

  return (
    <ProgramModuleContainer>
      <ProgramHeader {...headerProps} />
      {headerProps.dateDisplay === 'dateDisplayGroup' ? (
        <>
          {headerProps.dates.map((date, index) => {
            const cData = entriesProps[index]

            return (
              <ProgramEntriesGroup key={index}>
                <Heading type="h4" underlined>
                  {date.format('dddd, LL')}
                </Heading>
                {cData &&
                  cData.map((p, ind) => (
                    <ProgramEntry
                      {...p}
                      key={p.id}
                      toggleOpen={
                        p.id === toggleSession || currentToggle.includes(p.id)
                      }
                      hideTestSession={hideTestSession}
                      selectedDate={headerProps.selectedDate}
                    ></ProgramEntry>
                  ))}
              </ProgramEntriesGroup>
            )
          })}
        </>
      ) : (
        <ProgramEntriesContainer>
          {programEntriesToUse &&
            programEntriesToUse.map((p, index) => (
              <ProgramEntry
                {...p}
                key={p.id}
                toggleOpen={
                  p.id === toggleSession || currentToggle.includes(p.id)
                }
                hideTestSession={hideTestSession}
                selectedDate={headerProps.selectedDate}
              ></ProgramEntry>
            ))}
        </ProgramEntriesContainer>
      )}
    </ProgramModuleContainer>
  )
}

const ProgramEntriesContainer = styled.div({})
const ProgramEntriesGroup = styled.div(`
margin-bottom:80px;`)

const ProgramModuleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
