import styled from '@emotion/styled'
import { getSpacingString, ISpacing } from '../../../utility/styleProps'

interface IParagraph {
  spacing?: ISpacing
  dark?: boolean
}

export const Paragraph = styled.p<IParagraph>(
  ({ theme: { fonts, colors }, dark, spacing }) => ({
    marginTop: getSpacingString(spacing?.top),
    marginRight: getSpacingString(spacing?.right),
    marginBottom: getSpacingString(spacing?.bottom, 1),
    marginLeft: getSpacingString(spacing?.left),
    color: dark ? colors.textDark : colors.text,
    fontFamily: fonts.fontFamily,
    fontSize: fonts.fontSize,
    /**
     * Need to be a string, otherwise it will be compiled to 1.25px on
     * external packages, see https://github.com/emotion-js/emotion/pull/1931
     **/
    lineHeight: '28px',
    // default link Colors
    a: {
      color: colors.linkOnDark,
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    strong: {
      fontWeight: 700,
    },
  })
)
