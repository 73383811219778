import styled from '@emotion/styled'
import React from 'react'
import { mq } from '../../../utility/breakpoint'

interface KeyVisualProps {
  extraLayer?: boolean
}

export const KeyVisual: React.FC<KeyVisualProps> = ({
  children,
  extraLayer,
}) => {
  return (
    <KeyVisualContainer>
      <KeyVisualContent>{children}</KeyVisualContent>
    </KeyVisualContainer>
  )
}
export const KeyVisualContainer = styled.div(
  ({ theme: { grid } }) => `
  background:url(/assets/placeholder.svg) center center / cover;
  height:100vh;
  width:100%;
  grid-template-rows:1fr;
  p{
    text-shadow: 0px 0px 5px rgba(255, 255, 255, .6);
    ${mq[3]} {
      text-shadow:none;
    }
  }`
)

export const KeyVisualContent = styled.div(
  ({ theme: { grid } }) => `
  position:absolute;
  left:0;
  top:0;
  height:100vh;
  width:100%;`
)
