import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'

import { LoadingIndicator } from '../../atoms'

interface ExternalContentProps {
  uri: string
}

interface ExternalContentContainerProps {
  loading?: boolean
}

export const ExternalContent: React.FC<ExternalContentProps> = ({ uri }) => {
  const frame = useRef()

  const [loading, setLoading] = React.useState(true)

  return (
    <ExternalContentContainer loading={loading}>
      {loading && <LoadingIndicator />}
      <iframe
        allowFullScreen
        ref={frame}
        src={uri}
        frameBorder="0"
        onLoad={() => setLoading(false)}
      ></iframe>
    </ExternalContentContainer>
  )
}

const ExternalContentContainer = styled.div<ExternalContentContainerProps>(
  ({ theme: { colors, grid }, loading }) => `
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  iframe{
    opacity:${loading ? 0 : 1};
    width:${loading ? 0 : 100}%;
    height:100%;
  }

`
)
