import styled from '@emotion/styled'
import { TimeUtil, useOnClickOutside } from 'cuenect-web-core'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Text } from '../..'
import { TimepickerHours } from './timepickerHours'

export interface TimepickerProps {
  value?: dayjs.Dayjs
  min?: dayjs.Dayjs | undefined
  max?: dayjs.Dayjs | undefined
  minuteSteps?: number
  timezone?: string
  name?: string
  label?: string
  error?: boolean
  onChange(date: dayjs.Dayjs): void
}

export const Timepicker: React.FC<TimepickerProps> = ({
  value,
  min,
  max,
  minuteSteps = 15,
  label,
  name,
  error,
  onChange,
}) => {
  const { t } = useTranslation('salesform')

  const [currentValue, setCurrentValue] = React.useState(value)
  const [currentValueUtc, setCurrentValueUtc] = React.useState(value)
  const [showPicker, setShowPicker] = React.useState(false)

  const timepickerContainerRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(timepickerContainerRef, () => setShowPicker(false))

  const updateValue = val => {
    setCurrentValue(val)
    setShowPicker(false)
    onChange && onChange(val)
  }

  React.useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <>
      <TimepickerContainer ref={timepickerContainerRef}>
        <Input
          placeholder={label}
          name={name}
          error={error}
          value={currentValue.format('HH:mm')}
          onChange={() => null}
          onClick={() => setShowPicker(true)}
        />
        <TimepickerHours
          value={currentValue}
          onChange={val => {
            updateValue(val)
          }}
          visible={showPicker}
          min={min}
          max={max}
        />
        {error && <ErrorMessge>{t('error.time')}</ErrorMessge>}
      </TimepickerContainer>
    </>
  )
}

const ErrorMessge = styled(Text)(
  ({ theme: { colors } }) => `
color:${colors.error};
position:absolute;
left:5px;
top:55px;
`
)

const TimepickerContainer = styled.div(
  ({ theme: { colors } }) => `
   position:relative;
   display:inline-block;
  `
)
