import { AxiosResponse } from 'axios'
import { LocalStorageUtil, TimeUtil } from 'cuenect-web-core'
import { ProgramEvent } from '.'
import { AppointmentFormData } from '../components/organisms/appointmentForm'
import { apiConfig, EventBreakEvent, eventBreaks } from '../config'
import { AppointmentTopics } from '../utility'
import { HttpService } from './http'
export class AppointmentService {
  public static async getAppointmentSlots() {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${apiConfig.appointmentSlots}`,
      config: { withCredentials: true },
    })
    if (!result) {
      return false
    }

    return result.data
  }

  public static async getAppointments() {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${apiConfig.appointments}`,
      config: { withCredentials: true },
    })
    if (!result) {
      return false
    }

    return result.data
  }

  public static async sendForm(data: AppointmentFormData, topics) {
    const parsedData = AppointmentTopics.parseFormData(data, topics)
    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.appointments,
      data: parsedData,
      config: { withCredentials: true },
    })

    return result
  }

  public static parseForAgenda(
    data: ProgramEvent[],
    { title, description }: { title: string; description: string }
  ) {
    return data.map(appointment => {
      return {
        ...appointment,
        isAppointment: true,
        title,
        description,
      }
    })
  }
}
