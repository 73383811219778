import styled from '@emotion/styled'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import { BoothRatio } from '.'
import { ShowroomItem } from '../../../api'
import { useVodContext } from '../../../utility'

interface Hotspot extends ShowroomItem {
  boothRatio: BoothRatio
  active?: boolean
  onHighlight?(slug: string): void
}

interface HotspotImage extends ShowroomItem {
  highlight?: boolean
}

export const ShowRoomHotspot: React.FC<Hotspot> = props => {
  const { hotspot, boothRatio } = props
  const { dispatch: openVod } = useVodContext()
  const spotRef = React.useRef<Element>()
  const [highlight, setHighlight] = React.useState<boolean | undefined>(false)

  const over = () => {
    setHighlight(true)
    props.onHighlight && props.onHighlight(props.slug)
  }

  const out = () => {
    setHighlight(false)
    props.onHighlight && props.onHighlight('')
  }

  React.useEffect(() => {
    setHighlight(props.active)
    if (spotRef.current) {
      if (props.active) {
        // tslint:disable-next-line: no-inferred-empty-object-type
        ReactTooltip.show(spotRef.current)
      } else {
        // tslint:disable-next-line: no-inferred-empty-object-type
        ReactTooltip.hide(spotRef.current)
      }
    }
  }, [props.active])

  return (
    <>
      <Element {...props} highlight={highlight}>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width={`${boothRatio.x}px`}
          height={`${boothRatio.y}px`}
          viewBox={`0 0 ${boothRatio.x} ${boothRatio.y}`}
        >
          <path
            d={hotspot.map}
            ref={spotRef}
            onMouseOver={over}
            onMouseOut={out}
            onClick={() => {
              out()
              openVod({
                type: 'OPEN_VOD',
                src: props.video,
                trackingTitle: `${props.label}||${JSON.stringify({
                  uid: props.slug,
                })}`,
                playerName: 'Showroom Highlights',
              })
            }}
            data-tip={props.label}
          />
        </svg>
      </Element>{' '}
      <ReactTooltip effect="solid" className="showroomTooltip" />
    </>
  )
}
const Element = styled.div<HotspotImage>(
  ({ theme: { colors, grid }, highlight, hotspot: { src } }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:  url(${src}) center center / contain;
  pointer-events: none;
  opacity:${highlight ? 1 : 0};
  transition:opacity 0.25s;
  outline: 0px solid transparent;
  * {
    outline: 0px solid transparent;
  }
  svg{

   path{
     opacity:0;
    pointer-events:all;
    cursor:pointer;
   }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;}
 ;
`
)
